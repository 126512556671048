import breakpoints from './breakpoints'
import createBreakpointManager from 'break'
import regg from 'regg'
import * as Sentry from '@sentry/browser'
import 'classlist-polyfill'
import 'element-closest'
import 'nodelist-foreach-polyfill'
import 'mutationobserver-shim'

import config from '../whitelist-config.json'

const debug = require('debug')('base')
debug('Inits')
debug('NODE_ENV', process.env.NODE_ENV)
const bm = createBreakpointManager()
const serviceLocator = regg()
serviceLocator.register('config', config)

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: config.sentry.site.dsn,
    environment: process.env.SENTRY_ENV,
    release: config.release,
    ignoreErrors: [
      // Microsoft SafeLink Crawler https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062
      'Object Not Found Matching Id:'
    ]
  })
  Sentry.configureScope(scope => {
    scope.setTag('application', 'browser')
  })
  window.Sentry = Sentry
} else {
  window.Sentry = {
    captureException(e) {
      console.error(e)
    }
  }
}

// Look for an encoded article
const encodedEntityNode = document.querySelector('#encoded-entity')
if (encodedEntityNode) {
  const entityJson = encodedEntityNode.innerHTML
  try {
    window.__entity = JSON.parse(entityJson)
  } catch (e) {
    Sentry.captureException(e)
  }
} else {
  window.__entity = {}
}

const encodedUserNode = document.querySelector('#encoded-user')
if (encodedUserNode) {
  const userJson = encodedUserNode.innerHTML
  try {
    window.__userMeta = JSON.parse(userJson)
    if (window.__userMeta.user) {
      Sentry.configureScope(scope => {
        scope.setUser({ email: window.__userMeta.user.emailAddress })
      })
    }
  } catch (e) {
    Sentry.captureException(e)
  }
} else {
  window.__userMeta = {}
}

const components = [
  require('./tracking/init').default,
  require('./collection/init').default,
  require('./forms/init').default,
  require('./identity/init').default,
  require('./profile/init').default,
  require('./organisation/init').default,
  require('./load-more/init').default,
  require('./article/init').default,
  require('./article-hero/init').default,
  require('./primary-navigation/init').default,
  require('./explore-navigation/init').default,
  require('./video/init').default,
  require('./footer/init').default,
  require('./logo-carousel/init').default,
  require('./article-carousel/init').default,
  require('./article-meta-controls/init').default,
  require('./article-meta-controls-header/init').default,
  require('./article-footer/init').default,
  require('./search/init').default,
  require('./filterable-category/init').default,
  require('./filterable-label/init').default,
  require('./io-gallery-block/init').default,
  require('./following/init').default,
  require('./reading-list/init').default,
  require('./saved-list/init').default,
  require('./save-article/init').default,
  require('./hubspot-forms/init').default,
  require('./hubspot-custom/init').default,
  require('./personnel-carousel/init').default,
  require('./tabbed-page-intro/init').default,
  require('./vacancies/init').default,
  require('./static-carousel/init').default,
  require('./sub-navigation/init').default,
  require('./video-grid/init').default,
  require('./membership-table/init').default,
  require('./quote-carousel/init').default,
  require('./logo-grid/init').default,
  require('./faqs/init').default,
  require('./trial-form/init').default,
  require('./demo-form/init').default,
  require('./best-in-class/init').default,
  require('./challenge/init').default,
  require('./read-next/init').default,
  require('./ask-contagious-form/init').default,
  require('./ai-body/init').default
]

process.nextTick(() => {
  bm.add('desktop', breakpoints.desktopMq)
  bm.add('desktopNav', breakpoints.desktopNavMq)
  bm.add('tablet', breakpoints.tabletMq)
  bm.add('tabletNav', breakpoints.tabletNavMq)
  bm.add('mobile', breakpoints.mobileMq)
  bm.add('stickyNav', breakpoints.stickyNavMq)
  bm.add('actionList', breakpoints.actionListMq)
})

serviceLocator.register('breakpointManager', bm)

components.forEach(component => component(serviceLocator, window))
