import React from 'react' // eslint-disable-line no-unused-vars

// cache-buster: 40

// svgo ./src/component/Svg/source/ --enable=removeXMLNS --enable=removeDimensions --enable=removeTitle --enable=removeStyleElement --multipass --pretty --indent=2
import Add from './source/Add.svg'
import Add2 from './source/Add2.svg'
import Ai from './source/ai.svg'
import ArrowOpen from './source/ArrowOpen.svg'
import Check from './source/Check.svg'
import Close from './source/Close.svg'
import ContagiousAi from './source/ContagiousAi.svg'
import ContagiousAiStar from './source/ContagiousAiStar.svg'
import ContagiousAiText from './source/ContagiousAiText.svg'
import Cog from './source/Cog.svg'
import Calendar from './source/Calendar.svg'
import Dashboard from './source/Dashboard.svg'
import Date from './source/Date.svg'
import Down from './source/Down.svg'
import Facts from './source/Facts.svg'
import Filter from './source/Filter.svg'
import Left from './source/Left.svg'
import Link from './source/Link.svg'
import Logo from './source/Logo.svg'
import Menu from './source/Menu.svg'
import MenuAccent from './source/MenuAccent.svg'
import MenuThree from './source/MenuThree.svg'
import Minus from './source/Minus.svg'
import Notifications from './source/Notifications.svg'
import Ranking from './source/Ranking.svg'
import Right from './source/Right.svg'
import Rules from './source/Rules.svg'
import Up from './source/Up.svg'
import Warning from './source/Warning.svg'
import Email from './source/Email.svg'
import Facebook from './source/Facebook.svg'
import Instagram from './source/Instagram.svg'
import Linkedin from './source/Linkedin.svg'
import Twitter from './source/Twitter.svg'
import Play from './source/Play.svg'
import FooterAccent from './source/FooterAccent.svg'
import CornerAccent from './source/CornerAccent.svg'
import BlockAccent from './source/BlockAccent.svg'
import FullLogo from './source/FullLogo.svg'
import LogoText from './source/LogoText.svg'
import LogoPart1 from './source/LogoPart1.svg'
import LogoPart2 from './source/LogoPart2.svg'
import LogoPart3 from './source/LogoPart3.svg'
import LogoPart4 from './source/LogoPart4.svg'
import LogoPart5 from './source/LogoPart5.svg'
import Account from './source/Account.svg'
import Search from './source/Search.svg'
import IO from './source/IO.svg'
import ChevronRight from './source/ChevronRight.svg'
import ChevronLeft from './source/ChevronLeft.svg'
import ChevronDown from './source/ChevronDown.svg'
import ChevronUp from './source/ChevronUp.svg'
import QuestionMark from './source/QuestionMark.svg'
import Collect from './source/Collect.svg'
import CollectionAdd from './source/CollectionAdd.svg'
import PDF from './source/PDF.svg'
import PDF2 from './source/PDF2.svg'
import Share from './source/Share.svg'
import Delete from './source/Delete.svg'
import Drag from './source/Drag.svg'
import Edit from './source/Edit.svg'
import IOPrintLogo from './source/IOPrintLogo.svg'
import Bookmark from './source/Bookmark.svg'
import BookmarkBorder from './source/BookmarkBorder.svg'
import NotificationsFilled from './source/NotificationsFilled.svg'
import NotificationsNone from './source/NotificationsNone.svg'
import Visibility from './source/Visibility.svg'
import VisibilityOff from './source/VisibilityOff.svg'
import Social from './source/Social.svg'
import ArrowDown from './source/ArrowDown.svg'
import Plus from './source/Plus.svg'
import List from './source/List.svg'
import Rotate from './source/Rotate.svg'
import User from './source/User.svg'
import Settings from './source/Settings.svg'
import Tick from './source/Tick.svg'
import Download from './source/Download.svg'
import FullLogoTall from './source/FullLogoTall.svg'
import Positive from './source/Positive.svg'
import Negative from './source/Negative.svg'

const iconSvgs = {
  Add,
  Add2,
  Ai,
  ArrowOpen,
  Calendar,
  Check,
  Close,
  ContagiousAi,
  ContagiousAiStar,
  ContagiousAiText,
  Cog,
  Dashboard,
  Date,
  Down,
  Facts,
  Filter,
  Left,
  Link,
  Logo,
  Menu,
  MenuAccent,
  MenuThree,
  Minus,
  Notifications,
  NotificationsFilled,
  NotificationsNone,
  Ranking,
  Right,
  Rules,
  Up,
  Warning,
  Email,
  Facebook,
  Instagram,
  Linkedin,
  Twitter,
  Play,
  FooterAccent,
  CornerAccent,
  BlockAccent,
  FullLogo,
  LogoText,
  LogoPart1,
  LogoPart2,
  LogoPart3,
  LogoPart4,
  LogoPart5,
  Account,
  Search,
  IO,
  ChevronRight,
  ChevronLeft,
  ChevronDown,
  ChevronUp,
  QuestionMark,
  Negative,
  Collect,
  CollectionAdd,
  PDF,
  PDF2,
  Positive,
  Share,
  Delete,
  Drag,
  Edit,
  IOPrintLogo,
  Bookmark,
  BookmarkBorder,
  Visibility,
  VisibilityOff,
  Social,
  ArrowDown,
  Plus,
  List,
  Rotate,
  User,
  Settings,
  Tick,
  Download,
  FullLogoTall
}

export default iconSvgs
