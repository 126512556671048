import React from 'react'
import PropTypes from 'prop-types'
import { withColor } from '../../ColorContext'

import styles from './Loading.styl'

class Loading extends React.PureComponent {
  render() {
    const { color, className } = this.props

    const wrapperClasses = [styles.LoadingWrapper, className]
      .filter(Boolean)
      .join(' ')

    return (
      <div className={wrapperClasses}>
        <span
          className={styles.Loading}
          style={color ? { color } : undefined}
        />
      </div>
    )
  }
}

Loading.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string
}

export default withColor(Loading)
