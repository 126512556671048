import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styles from './SuggestedPrompts.styl'
import Loading from '../../primitive/Loading'

const SuggestedPrompts = ({ onPromptClick }) => {
  const [prompts, setPrompts] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  useEffect(() => {
    const fetchPrompts = async () => {
      try {
        const response = await fetch('/api/ai/suggested-prompts', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          credentials: 'include',
          body: JSON.stringify({
            count: 5,
            tenantIds: [3]
          })
        })

        if (!response.ok) {
          throw new Error(`Server returned status ${response.status}`)
        }

        const data = await response.json()

        if (data.error) {
          throw new Error(data.error)
        }

        setPrompts(data)
        setLoading(false)
        setError(false)
      } catch (error) {
        console.error('Error fetching prompts:', error)
        setError(true)
        setLoading(false)
      }
    }

    fetchPrompts()
  }, [])

  if (loading) {
    return (
      <div className={styles.AiSuggestedPrompts}>
        <Loading />
      </div>
    )
  }

  if (error) {
    return (
      <div className={styles.AiSuggestedPrompts}>
        <p>
          Apologies, something’s gone wrong. Please try again later, or contact
          us for support.
        </p>
      </div>
    )
  }

  return (
    <div className={styles.AiSuggestedPrompts}>
      <p className={styles.AiSuggestedPrompts_description}>
        Not sure what to ask? Here are some suggested prompts to get you started
      </p>
      <ul className={styles.AiSuggestedPrompts_prompts}>
        {prompts.map((prompt, index) => (
          <li key={index} onClick={() => onPromptClick(prompt)}>
            {prompt.prompt}
          </li>
        ))}
      </ul>
    </div>
  )
}

SuggestedPrompts.propTypes = {
  onPromptClick: PropTypes.func
}

export default SuggestedPrompts
