import renderComponent from '../lib/component-renderer'

import AiBody from '../../../../components/site/widgets/ai-body/component/AiBody'

const debug = require('debug')('ai body')

export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    debug('init ai body')
    renderComponent('.js-ai-body', AiBody)
  })
}
