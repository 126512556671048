import React, { Fragment } from 'react'
import { string, bool, array, shape, func } from 'prop-types'

import AiSavedResponse from '../../layout/component/AiBody/component/AiSavedResponse'
import ArticleCard from '../../layout/component/ArticleCard'
import CollectionPreview from '../../layout/component/CollectionPreview'
import Divider from '../../layout/component/Divider'
import Icon from '../../layout/component/primitive/Icon'

import styles from './SavedLists.module.styl'

const SavedList = ({
  userId,
  isOwner,
  savedLists,
  list,
  onDeleteItem,
  onRemoveItem
}) => {
  const isListMember = list.users.includes(userId)
  const isEditableCollaborativeList = list.collaborative ? isListMember : false
  const canDelete = list.isDefault
    ? false
    : isOwner
    ? true
    : isEditableCollaborativeList
  const handleDelete = item =>
    canDelete ? onDeleteItem.bind(null, item) : null

  const handleAiDelete = async item => {
    try {
      const response = await fetch('/api/ai/remove-saved-prompt', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({
          responseId: item.responseId,
          iqUserId: userId
        })
      })
      if (response.ok) {
        onRemoveItem(item.type, item.id)
      }
    } catch (error) {
      console.error('Error removing saved prompt')
    }
  }
  return (
    <Fragment>
      {list.__items && (
        <>
          {list.__items.length === 0 ? (
            <p>
              {list.isDefault && (
                <>
                  You haven’t saved anything yet. Start saving items by clicking
                  the{' '}
                  <Icon type={'BookmarkBorder'} a11yText={'Save'} width={16} />{' '}
                  icon and you’ll find them all here.
                </>
              )}
              {!list.isDefault && (
                <>
                  You haven’t saved anything to this list. Start saving items by
                  clicking the arrow next to the{' '}
                  <Icon type={'BookmarkBorder'} a11yText={'Save'} width={16} />{' '}
                  icon and selecting this list.
                </>
              )}
            </p>
          ) : (
            <ul className={styles.List}>
              {list.__items.map(item => (
                <li key={`SavedListItem-${item.id}`}>
                  <div className={styles.ListItemInner}>
                    {item.type === 'article' && (
                      <ArticleCard
                        className={styles.ArticleCard}
                        onDelete={handleDelete(item)}
                        smallPreview
                        savedLists={savedLists}
                        hideSaveDropdown={!list.isDefault}
                        onRemoveItem={onRemoveItem}
                        crop="Square"
                        imageConstrain={500}
                        {...item}
                      />
                    )}
                    {item.type === 'collection' && (
                      <CollectionPreview
                        gridClass={styles.CollectionPreviewInner}
                        heading={item.name}
                        onDelete={handleDelete(item)}
                        href={item.link}
                        description={item.description}
                        dateSubtitle={item.__date}
                        imageSource={item.__images}
                        collectionId={item.collectionId}
                        savedLists={savedLists}
                        hideSaveDropdown={!list.isDefault}
                        onRemoveItem={onRemoveItem}
                        collectionList
                      />
                    )}
                    {item.type === 'ai-chat' && (
                      <AiSavedResponse
                        headline={item.prompt}
                        label="AI Chat"
                        id={item.id}
                        displayDate={item.createdAt}
                        onDelete={() => handleAiDelete(item)}
                      />
                    )}
                  </div>
                  <Divider />
                </li>
              ))}
            </ul>
          )}
        </>
      )}
    </Fragment>
  )
}

const savedListShape = shape({
  _id: string,
  name: string.isRequired
})

SavedList.propTypes = {
  isOwner: bool,
  userId: string.isRequired,
  list: savedListShape,
  savedLists: array.isRequired,
  onDeleteItem: func,
  onRemoveItem: func
}

export default SavedList
