import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styles from './AiAnswer.styl'
import Modal from '../../Modal'
import Button from '../../primitive/Button'
import Icon from '../../primitive/Icon'
import moment from 'moment'
import HeadingSlash from '../../primitive/HeadingSlash'
import Loading from '../../primitive/Loading'

const positiveFeedbackOptions = [
  { label: 'Expected response', value: 'Expected' },
  { label: 'Good response, useful information', value: 'Good' },
  { label: 'Perfect, very useful information', value: 'Perfect' }
]
const negativeFeedbackOptions = [
  { label: 'It was too long', value: 'TooLong' },
  { label: 'It was too short', value: 'TooShort' },
  { label: 'It was inaccurate/had incorrect information', value: 'Inaccurate' },
  { label: `It wasn't relevant to the question`, value: 'Irrelevant' },
  { label: `It doesn't match with the sources`, value: 'MisMatchedSources' },
  { label: `The answer wasn't easy to understand`, value: 'Unclear' },
  { label: 'Other', value: 'Other' }
]

const AiAnswer = ({ answer, sources, additionalSources, answerId, user }) => {
  const [isSaved, setIsSaved] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [feedbackModalType, setFeedbackModalType] = useState(null)
  const [feedbackMessage, setFeedbackMessage] = useState(null)
  const [selectedFeedback, setSelectedFeedback] = useState(null)

  const [feedbackText, setFeedbackText] = useState('')

  const formattedAnswer = answer ? answer.replace(/\n/g, '<br />') : ''

  const openFeedbackModal = type => {
    setFeedbackModalType(type)
    setFeedbackMessage(null)
    setSelectedFeedback(null)
    setFeedbackText('')
  }

  const closeModal = () => {
    setFeedbackModalType(null)
    setFeedbackMessage(null)
    setSelectedFeedback(null)
    setFeedbackText('')
  }

  const handleSubmitFeedback = async () => {
    if (!selectedFeedback) return

    try {
      const response = await fetch('/api/ai/feedback', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({
          feedback: [selectedFeedback.value],
          feedbackText,
          responseId: answerId,
          userId: user ? user._id : null
        })
      })

      if (!response.ok) {
        throw new Error('Failed to submit feedback')
      }

      setFeedbackMessage(
        `Thanks for your feedback: "${selectedFeedback.label}".`
      )
    } catch (error) {
      console.error('Error submitting feedback', error)
      setFeedbackMessage('Sorry, there was a problem submitting your feedback.')
    }
  }

  const handleSaveResponse = async () => {
    setIsLoading(true)
    try {
      const response = await fetch('/api/ai/save-response', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({
          iqUserId: user._id,
          responseId: answerId
        })
      })

      if (!response.ok) {
        throw new Error('Failed to save the response')
      } else {
        setIsSaved(true)
      }
    } catch (error) {
      console.error('Error saving response', error)
    } finally {
      setIsLoading(false)
    }
  }

  const getCurrentFeedbackOptions = () => {
    if (feedbackModalType === 'positive') return positiveFeedbackOptions
    if (feedbackModalType === 'negative') return negativeFeedbackOptions
    return []
  }

  const shouldShowTextField = () => {
    if (feedbackModalType === 'positive') return true
    if (
      feedbackModalType === 'negative' &&
      selectedFeedback &&
      selectedFeedback.value === 'Other'
    ) {
      return true
    }
    return false
  }

  return (
    <div className={styles.AiAnswer}>
      {answer && (
        <div
          className={styles.AiAnswer_content}
          dangerouslySetInnerHTML={{ __html: formattedAnswer }}
        />
      )}

      {(sources.length > 0 || additionalSources.length > 0) && (
        <div className={styles.AiAnswer_sources}>
          {sources.length > 0 && (
            <>
              <h3 className={styles.AiAnswer_sources_heading}>
                <HeadingSlash>Sources</HeadingSlash>
              </h3>
              <ol className={styles.AiAnswer_sources_list}>
                {sources.filter(Boolean).map(source => (
                  <li
                    key={source.contentId}
                    className={styles.AiAnswer_sources_list_item}
                    value={source.sourceId}
                  >
                    <a
                      href={source.__fullUrlPath}
                      target="_blank"
                    >
                      {source.headline}
                    </a>
                    <br />
                    {source.sell} |{' '}
                    {moment(source.displayDate).format('MMMM YYYY')}
                  </li>
                ))}
              </ol>
            </>
          )}

          {additionalSources.length > 0 && (
            <>
              <hr />
              <h3
                className={styles.AiAnswer_sources_heading}
                style={{ marginTop: '12px' }}
              >
                <HeadingSlash>Wider reading</HeadingSlash>
              </h3>
              <ol className={styles.AiAnswer_sources_list}>
                {additionalSources.filter(Boolean).map(source => (
                  <li
                    key={source.contentId}
                    className={styles.AiAnswer_sources_list_item}
                    value={source.sourceId}
                  >
                    <a
                      href={source.__fullUrlPath}
                      target="_blank"
                    >
                      {source.headline}
                    </a>
                    <br />
                    {source.sell} |{' '}
                    {moment(source.displayDate).format('MMMM YYYY')}
                  </li>
                ))}
              </ol>
            </>
          )}
        </div>
      )}

      {answer && (
        <div className={styles.AiAnswer_actions}>
          <div className={styles.AiAnswer_actions_feedback}>
            <span className={styles.AiAnswer_actions_feedback_title}>
              Was this helpful?
            </span>
            <div className={styles.AiAnswer_actions_feedback_buttons}>
              <div
                className={styles.AiAnswer_actions_feedback_buttons_button}
                onClick={() => openFeedbackModal('positive')}
              >
                <Icon width={24} height={24} type="Positive" />
              </div>
              <div
                className={styles.AiAnswer_actions_feedback_buttons_button}
                onClick={() => openFeedbackModal('negative')}
              >
                <Icon width={24} height={24} type="Negative" />
              </div>
            </div>
          </div>

          <div className={styles.AiAnswer_actions_save}>
            <span className={styles.AiAnswer_actions_save_title}>
              Do you want to save this response?
            </span>{' '}
            <Button
              className={styles.AiAnswer_actions_save_button}
              outline
              onClick={handleSaveResponse}
              disabled={isSaved}
            >
              {isLoading ? (
                <Loading
                  className={styles.AiAnswer_actions_save_button_loading}
                />
              ) : isSaved ? (
                'Saved'
              ) : (
                'Save response'
              )}
            </Button>
          </div>
        </div>
      )}

      {feedbackModalType && (
        <Modal
          title={`${
            feedbackModalType === 'positive' ? 'Positive' : 'Negative'
          } Feedback`}
          onClose={closeModal}
        >
          {feedbackMessage ? (
            <div>
              <p>{feedbackMessage}</p>
            </div>
          ) : (
            <div>
              <p>Please select one option, then submit:</p>
              <div style={{ marginTop: '1rem' }}>
                {getCurrentFeedbackOptions().map(option => (
                  <div key={option.value} style={{ marginBottom: '0.5rem' }}>
                    <label style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                      <input
                        type="radio"
                        name="feedbackOption"
                        value={option.value}
                        checked={
                          selectedFeedback &&
                          selectedFeedback.value === option.value
                        }
                        onChange={() => setSelectedFeedback(option)}
                        style={{ marginRight: '0.25rem' }}
                      />
                      <span>{option.label}</span>
                    </label>
                  </div>
                ))}
              </div>

              {shouldShowTextField() && (
                <div style={{ marginTop: '1rem' }}>
                  <textarea
                    className={styles.AiAnswer_actions_feedback_textarea}
                    id="feedbackText"
                    placeholder='Tell us more'
                    value={feedbackText}
                    onChange={e => setFeedbackText(e.target.value)}
                  />
                </div>
              )}

              <Button
                onClick={handleSubmitFeedback}
                disabled={!selectedFeedback}
                style={{ marginTop: '1rem' }}
              >
                Submit Feedback
              </Button>
            </div>
          )}
        </Modal>
      )}
    </div>
  )
}

AiAnswer.propTypes = {
  answer: PropTypes.string.isRequired,
  sources: PropTypes.array,
  additionalSources: PropTypes.array,
  answerId: PropTypes.string.isRequired,
  user: PropTypes.object
}

export default AiAnswer
