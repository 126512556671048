import React from 'react'
import PropTypes from 'prop-types'

import AiBody from '../../../layout/component/AiBody'

const AiBodyWidget = props => {
  const { items, user } = props
  return (
    <div className="widget widget--ai-body">
      <AiBody
        user={user}
        items={items}
      />
    </div>
  )
}

AiBodyWidget.propTypes = {
  items: PropTypes.array,
  user: PropTypes.object,
}

export default AiBodyWidget
