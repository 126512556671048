import React, { useEffect, useRef, useState } from 'react'
import { func, arrayOf, bool, object, number, string, array } from 'prop-types'
import classnames from 'classnames'
import useEscapeKey from '../../../lib/hook/useEscapeKey'
import debounce from 'lodash.debounce'

import Headroom from 'react-headroom'

import styles from './ExploreNavigation.styl'

import NavToggle from './component/NavToggle'
import NavLogo from './component/NavLogo'
import NavSearch from './component/NavSearch'
import NavLinks from './component/NavLinks'
import SlideNav from '../../SlideNav'
import ButtonSimple from '../../primitive/ButtonSimple'
import TickerBar from './component/TickerBar'
import DesktopNavItems from './component/DesktopNavItems'
import Icon from '../../primitive/Icon'

const ExploreNavigation = ({
  categorySuggestions,
  contentSuggestions,
  suggestionsLoading,
  onSearch,
  minimal,
  newFollowingCount,
  panelLinks,
  sticky,
  onboarding,
  inviteLink,
  tickerBar,
  caseStudiesArticles,
  learningArticles,
  displayBanner,
  bannerCopy,
  bannerCtaCopy,
  bannerCtaLink
}) => {
  const [expanded, setExpanded] = useState(false)
  const [mobileSearchOpen, setMobileSearchOpen] = useState(false)

  const [isTransparent, setIsTransparent] = useState(sticky)
  const toggle = useRef()
  const [isSearchFocus, setIsSearchFocus] = useState(false)
  const [currentPath, setCurrentPath] = useState('')

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setCurrentPath(window.location.pathname)
    }

    if (sticky) {
      window.addEventListener('scroll', handleScroll)
      return () => {
        window.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  const handleScroll = debounce(() => {
    const currentScrollPos =
      typeof window !== 'undefined' ? window.pageYOffset : 0
    if (currentScrollPos > 50) {
      setIsTransparent(false)
    } else {
      setIsTransparent(true)
    }
  }, 10)

  const handleToggleExpanded = () => {
    setExpanded(!expanded)
  }

  const handleCloseExpanded = () => {
    setExpanded(false)
    toggle.current && toggle.current.focus()
  }

  useEscapeKey(expanded && handleCloseExpanded)

  const isAiPage = currentPath === '/iq/ai'

  return (
    <>
      <Headroom
        disable={sticky}
        className={classnames(
          styles.HeadroomContainer,
          sticky && styles.sticky
        )}
      >
        <div
          className={classnames(
            styles.ExploreNavigation,
            expanded && styles.expanded,
            isTransparent && styles.transparent
          )}
        >
          {!minimal && !onboarding && tickerBar && <TickerBar {...tickerBar} />}
          <div className={styles.ExploreNavigationInner}>
            {!minimal && <div className={styles.MobileNavSpacer} />}
            <NavLogo label="Contagious IQ" url="/iq" />
            {!minimal && (
              <NavToggle
                setRef={toggle}
                smallScreen
                expanded={expanded}
                onClick={handleToggleExpanded}
              />
            )}

            {!minimal && (
              <>
                <div className={styles.ExploreNavigationCenter}>
                  <div className={styles.ExploreNavigationSearch}>
                    <NavSearch
                      onSearch={onSearch}
                      mobileSearchOpen={mobileSearchOpen}
                      setMobileSearchOpen={setMobileSearchOpen}
                      suggestionsLoading={suggestionsLoading}
                      suggestions={{
                        categories: categorySuggestions,
                        content: contentSuggestions
                      }}
                      isSearchFocus={isSearchFocus}
                      setIsSearchFocus={setIsSearchFocus}
                    />
                  </div>
                  <a className={styles.AiButton} href="/iq/ai">
                    <Icon className={styles.AiButton_star} width={30} type="ContagiousAiStar" />
                    <Icon className={styles.AiButton_text} width={145} type="ContagiousAiText" />
                  </a>
                </div>

                <div className={styles.ExploreNavigationLinks}>
                  <NavLinks newFollowingCount={newFollowingCount} />
                </div>
              </>
            )}
            {onboarding && (
              <nav className={styles.OnboardingNav}>
                <ButtonSimple
                  ariaLabel="get in touch"
                  className={styles.TextButton}
                  href="mailto:support@contagious.com"
                >
                  Get in touch
                </ButtonSimple>
                {inviteLink && (
                  <ButtonSimple ariaLabel="register" href={inviteLink}>
                    Register
                  </ButtonSimple>
                )}
              </nav>
            )}
          </div>
          {!minimal && (
            <DesktopNavItems
              panelLinks={panelLinks}
              caseStudiesArticles={caseStudiesArticles}
              learningArticles={learningArticles}
              isSearchFocus={isSearchFocus}
            />
          )}
        </div>
        {!minimal && (
          <div className={styles.ExploreNavigationMobileSearch}>
            <div className={styles.NavSearchToggle}>
              <button
                aria-label="Reveal Search"
                className={styles.NavSearchToggleButton}
                onClick={() => setMobileSearchOpen(true)}
              >
                <Icon
                  width={16}
                  height={16}
                  type="Search"
                  a11yText="Reveal Search"
                />
                <span>Search</span>
              </button>
            </div>
          </div>
        )}
        <SlideNav
          onClose={handleCloseExpanded}
          links={panelLinks}
          expanded={expanded}
        />
      </Headroom>
      {!minimal && displayBanner && !isAiPage && (
        <div className={styles.AiBanner}>
          <p>{bannerCopy}</p>
          <ButtonSimple className={styles.AiBanner_button} white href={bannerCtaLink}>
            {bannerCtaCopy}
          </ButtonSimple>
        </div>
      )}
    </>
  )
}

ExploreNavigation.defaultProps = {
  secondaryLinks: {}
}

ExploreNavigation.propTypes = {
  onSearch: func,
  categorySuggestions: arrayOf(object),
  contentSuggestions: object,
  suggestionsLoading: bool,
  minimal: bool,
  newFollowingCount: number,
  panelLinks: object,
  sticky: bool,
  onboarding: bool,
  inviteLink: string,
  tickerBar: object,
  caseStudiesArticles: array,
  learningArticles: array,
  displayBanner: bool,
  bannerCopy: string,
  bannerCtaCopy: string,
  bannerCtaLink: string
}

export default ExploreNavigation
