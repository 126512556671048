import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import HeadingSlash from '../../primitive/HeadingSlash'
import { Grid, GridItem } from '../../primitive/Grid'
import TagLabel from '../../TagLabel'
import Icon from '../../primitive/Icon'
import ButtonSimple from '../../primitive/ButtonSimple'

import styles from './AiSavedResponse.styl'

const AiSavedResponse = ({ headline, label, id, displayDate, onDelete }) => {
  const formattedDisplayDate = displayDate
    ? moment(displayDate)
        .format('D MMM YYYY')
        .toUpperCase()
    : ''

  const handleDelete = e => {
    e.preventDefault()
    onDelete()
  }

  return (
    <div className={styles.AiSavedResponse}>
      <Grid className={styles.AiSavedResponseWrapper} gutterless>
        <GridItem className={styles.AiSavedResponseText}>
          <a
            aria-label={headline}
            className={styles.AiSavedResponseText_link}
            href={`/iq/ai?id=${id}`}
          >
            <div>
              <h3 className={styles.AiSavedResponseText_heading}>
                <HeadingSlash>{headline}</HeadingSlash>
              </h3>
            </div>
            {label && (
              <TagLabel className={styles.AiSavedResponseText_label}>
                {label}
              </TagLabel>
            )}
          </a>
          <div className={styles.AiSavedResponseFooter}>
            {formattedDisplayDate && (
              <p className={styles.AiSavedResponseFooter_date}>
                {formattedDisplayDate}
              </p>
            )}
            <ButtonSimple
              className={styles.AiSavedResponseFooter_button}
              outline
              onClick={handleDelete}
              ariaLabel="delete"
            >
              <Icon width={16} height={16} type="Delete" a11yText="Delete" />
            </ButtonSimple>
          </div>
        </GridItem>
      </Grid>
    </div>
  )
}

AiSavedResponse.propTypes = {
  headline: PropTypes.string,
  label: PropTypes.string,
  displayDate: PropTypes.string,
  onDelete: PropTypes.func,
  id: PropTypes.string
}

export default AiSavedResponse
